<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <!--
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>
            -->

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <!--
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
              -->
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/register_background.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-h4 font-weight-semibold text--primary mb-2">
                    เตรียมพบกับ "การอู้"
                  </p>
                  <p class="mb-2">
                    สมัครสมาชิก และกรอกข้อมูลเกี่ยวกับธุรกิจ แค่นิดเดียวเท่านั้น!
                  </p>
                </v-card-text>
                
                <!-- register form -->
                <v-card-text>
                  <v-form
                    ref="registerForm"
                    @submit.prevent="handleFormSubmit"
                    v-model="isFormValid"
                  >
                    <v-text-field
                      v-model="name"
                      outlined
                      label="Name"
                      placeholder="name"                      
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                    ></v-text-field>

                    <v-text-field
                      v-model="lastname"
                      outlined
                      label="Lastname"
                      placeholder="lastname"                      
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                    ></v-text-field>

                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"                      
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-2"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"                      
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required, validators.passwordValidator]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-text-field
                      v-model="confirmPassword"
                      outlined
                      :type="isConfirmPasswordVisible ? 'text' : 'password'"
                      label="Confirm Password"                      
                      placeholder="Confirm Password"
                      :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required, validators.confirmedValidator(password, confirmPassword)]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center flex-wrap">                    
                      <v-checkbox v-model="isAgreeingTerm">
                      <template v-slot:label>
                          <div>
                            ฉันได้อ่าน และยอมรับ 
                            <a
                              target="_blank"
                              href="/policy"
                              @click.stop                              
                              class="text-decoration-underline"
                            >
                              กฏ นโยบาย และข้อบังคับ
                            </a>
                            <!--
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a
                                  target="_blank"
                                  href="/policy"
                                  @click.stop
                                  v-on="on"
                                >
                                  กฏ นโยบาย และข้อบังคับ
                                </a>
                              </template>
                              เปิดลิ้งในหน้าใหม่
                            </v-tooltip>       
                            -->                     
                          </div>                          
                        </template>
                      </v-checkbox>                       
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      :loading="isSubmitting"
                      :disabled="!isFormValid || !isAgreeingTerm"
                    >
                      สมัครเลย!
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    มีบัญชีอยู่แล้ว?
                  </p>
                  <router-link :to="{name:'auth-login', query: {redirectTo: $route.query.redirectTo}}">
                    เข้าสู่ระบบ
                  </router-link>
                </v-card-text>                      
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { confirmedValidator, emailValidator, passwordValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { asyncPost } from '../helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      isSubmitting: false,
      name: '',
      lastname: '',
      email: '',
      password: '',
      confirmPassword: '',
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      isAgreeingTerm: false,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        confirmedValidator,
        passwordValidator,
      },
    }
  },
  methods: {
    async handleFormSubmit() {
      this.isSubmitting = true
      try {
        let response = await asyncPost('/register', {
          name: this.name,
          lastname: this.lastname,
          email: this.email,
          password: this.password,
          confirmPassword: this.confirmPassword,
        })
        const { accessToken, wsToken, user } = response

        this.$store.commit('auth/LOGIN', { accessToken: accessToken, wsToken: wsToken })
        this.$store.commit('auth/SET_USER_PROFILE', user)

        this.$router.push({ name: 'verifycode', query: { redirectTo: this.$route.query.redirectTo } })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.isSubmitting = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>